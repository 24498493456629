import { get } from 'lodash';
import { navigate } from '@reach/router';
import moment from 'moment';

import {
  Basket,
  Pet,
  DogsAgeType,
  Address,
  BasketLine,
  PetList,
  Product,
  UserAddress,
  ProductInfo,
  ProductAttributeValues,
  ProductAttributeName,
} from 'services/models';
import { addInviteCodeToUserSession } from 'services/voucher.api';

export const monthsArray = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const smallMonthsArray = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const getDateString = (date: string) => {
  if (!date) return '';
  const nextOrderDateObj = new Date(date);
  const dd = nextOrderDateObj.getDate();
  const month = monthsArray[nextOrderDateObj.getMonth()];
  const yyyy = nextOrderDateObj.getFullYear();
  return `${dd} ${month} ${yyyy}`;
};

export const formatDate = (date: string | Date, format?: string) => {
  if (!date) return '';
  const dateObj = typeof date !== 'string' ? date : new Date(date);
  const day = dateObj.getDate();
  const dd = day < 10 ? `0${day}` : day;
  const month = dateObj.getMonth() + 1;
  const mm = month < 10 ? `0${month}` : month;
  const yyyy = dateObj.getFullYear();
  const yy = yyyy.toString().substr(-2);

  switch (format) {
    case 'dd.mm.yyyy': {
      return `${dd}.${mm}.${yyyy}`;
    }
    case 'dd-mm-yyyy': {
      return `${dd}-${mm}-${yyyy}`;
    }
    case 'mm-dd-yyyy': {
      return `${mm}-${dd}-${yyyy}`;
    }
    case 'yyyy-mm-dd': {
      return `${yyyy}-${mm}-${dd}`;
    }
    case 'dd/mm/yyyy': {
      return `${dd}/${mm}/${yyyy}`;
    }
    case 'dd.mm.yy': {
      return `${dd}.${mm}.${yy}`;
    }
    default: {
      return `${dd}-${smallMonthsArray[mm - 1]}-${yyyy}`;
    }
  }
};

export const navigateToDashboard = (message: string) => {
  navigate('/account/dashboard/', {
    state: {
      notificationText: message,
    },
  });
};

export const getTomorrowsDateObject = () => {
  return new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
};

export const setDays = (days: number, format = 'dd.mm.yyyy',fromDate?: string|undefined) => {
  const currentTime = fromDate ? new Date(fromDate) : new Date();
  currentTime.setDate(currentTime.getDate() + days);
  return formatDate(currentTime, format);
};

export const addDays = (date, days, format = 'dd.mm.yy') => {
  date = date || new Date();
  date.setDate(date.getDate() + days);
  return formatDate(date, format);
};

export const monthDiff = (date1: Date, date2: Date) => {
  let months;
  months = (date2.getFullYear() - date1.getFullYear()) * 12;
  months -= date1.getMonth() + 1;
  months += date2.getMonth();
  return months <= 0 ? 0 : months;
};

export const monthsToDate = (months: number) => {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - months);
  return currentDate;
};

export const dateToMonths = (date?: Date) => {
  const birthDate = moment(date);
  const currentDate = moment();
  return currentDate.diff(birthDate, 'months');
};

export const monthsToYears = (months: number) => {
  const years = Math.floor(months / 12);
  const rem = months % 12;
  return { years, months: rem };
};

export const getAge = (totalMonths: number) => {
  if (totalMonths < 12) {
    return 'puppy';
  }

  if (totalMonths >= 12 && totalMonths <= 96) {
    return 'adult';
  }

  return 'senior';
};

export const diffInDays = (date1, date2) => {
  const diffTime = Math.abs(date2 - date1);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const constructPetUrl = petId => {
  return `${process.env.RESOURCE_PATH_HOST}/api/pet/${petId}/`;
};

export const constructProductUrl = productId => {
  return `${process.env.RESOURCE_PATH_HOST}/api/products/${productId}/`;
};

export const getChickenBoxProductUrl = (productId: Number | null = null) => {
  return constructProductUrl(productId || process.env.PRODUCT_ID_CHICKEN_BOX);
};

export const getBeefTreatProductUrl = () => {
  return constructProductUrl(process.env.PRODUCT_ID_TREAT_BEEF);
};

export const getCountryUrl = () => {
  return `${process.env.RESOURCE_PATH_HOST}/api/countries/NZ/`;
};

export const getProductImageUrl = (path: string) => {
  // function is kept to make any changes here in case of url changes in future
  return path;
};

export const createPetCreationPayload = (
  dogsName,
  ageName,
  activity_level,
  weight,
  ageType,
  age,
  foodPercentage,
  breed,
) => {
  const petCreationPayload: Pet = {
    name: dogsName,
    age: ageName,
    activity_level,
    weight,
    others: JSON.stringify({ type: ageType, value: age }),
    food_percent: foodPercentage,
    breed,
  };

  if (ageType === DogsAgeType.BIRTH_DATE) {
    petCreationPayload.birth_date = formatDate(age, 'yyyy-mm-dd');
  }

  if (ageType === DogsAgeType.YEARS) {
    const { years = 0, months = 0 } = age;
    const birthDate = monthsToDate(years * 12 + months);
    petCreationPayload.birth_date = formatDate(birthDate, 'yyyy-mm-dd');
  }
  return petCreationPayload;
};

export const calculateDiscount = (basket: Basket) => {
  return (
    Math.round(
      (parseFloat(basket.total_incl_tax_excl_discounts) -
        parseFloat(basket.total_incl_tax) +
        Number.EPSILON) *
        100,
    ) / 100
  );
};

export const getDiscountPercentageInBasket = (basket: Basket) => {
  const original = parseFloat(basket.total_incl_tax_excl_discounts);
  const discounted = parseFloat(basket.total_incl_tax);
  return Math.round(((original - discounted) * 100) / original);
};

export const getAddressDisplay = (address: Address | UserAddress) => {
  return `${address.line1}${address.line2 ? ', ' + address.line2 : ''}, ${
    address.line3
  }`;
};

export const getVoucherInBasket = (basket?: Basket) => {
  let voucher = null;
  if (basket && basket.voucher_discounts && basket.voucher_discounts[0]) {
    voucher = basket.voucher_discounts[0].voucher;
  }
  return voucher;
};

export const getChickenDogBoxesCount = (basketLines: BasketLine[]) => {
  let count = 0;
  basketLines.forEach(basketLine => {
    if (basketLine.product === getChickenBoxProductUrl()) {
      count++;
    }
  });
  return count;
};

export const getProductBoxesCount = (
  basketLines: BasketLine[],
  productId: number,
) => {
  let count = 0;
  basketLines.forEach(basketLine => {
    if (basketLine.product === constructProductUrl(productId)) {
      count++;
    }
  });
  return count;
};

export const getTreatsCount = (
  basketLines: BasketLine[],
  mainProductId: number,
) => {
  let count = 0;
  basketLines.forEach(basketLine => {
    if (basketLine.product !== constructProductUrl(mainProductId)) {
      count++;
    }
  });
  return count;
};

export const isBrowser = () => typeof window !== 'undefined';

export const getNoticeBrowserStatus = (noticeName: string) => {
  return isBrowser() ? !!window.localStorage.getItem(noticeName) : false;
};

export const setNoticeBrowserStatus = (noticeName: string) => {
  if (isBrowser()) {
    window.localStorage.setItem(noticeName, formatDate(new Date()));
  }
};

export const fetchIdFromUrl = (basketUrl: string) => {
  const allComponents = basketUrl.split('/');
  const id = allComponents[allComponents.length - 2];
  return Number(id);
};

export const updateAllPetsList = (list: PetList[]) => {
  if (isBrowser()) {
    const dataToStore = JSON.stringify(list);
    localStorage.setItem('dogsList', dataToStore);
  }
};

export const getAllPetsList = () => {
  if (isBrowser()) {
    const allPets = localStorage.getItem('dogsList');
    return JSON.parse(allPets);
  }
};

export const deleteAllPetsList = () => {
  isBrowser() && localStorage.removeItem('dogsList');
};

export const updateCheckoutState = (checkoutState: any) => {
  if (isBrowser()) {
    const checkoutStateJSON = JSON.stringify(checkoutState);
    localStorage.setItem('checkoutState', checkoutStateJSON);
  }
};

export const getCheckoutState = () => {
  if (isBrowser()) {
    const checkoutStateJSON = localStorage.getItem('checkoutState') || '{}';
    return JSON.parse(checkoutStateJSON);
  }
};

export const deleteCheckoutState = () => {
  isBrowser() && localStorage.removeItem('checkoutState');
};

export const removeSpacesFromQueryParams = (value: string) => {
  if (value.includes('%20')) {
    return value.replace(/%20/g, ' ');
  }
  return value;
};

export const updatePetFrequency = (
  petId: number,
  updatedFrequency: number,
  petList?: PetList[],
) => {
  const currentList = petList || getAllPetsList();
  let updatedList = [];
  currentList.map(item => {
    const newItem = { ...item };
    const data = newItem.petDetails;
    if (data.id === petId) {
      newItem.suggestion = {
        ...newItem.suggestion,
        frequency: updatedFrequency,
      };
    }
    updatedList.push(newItem);
  });
  updateAllPetsList(updatedList);
  return updatedList;
};

export const getProductInfoFromId = (
  allProducts: Product[],
  productId: string | number = process.env.PRODUCT_ID_CHICKEN_BOX,
) => allProducts.find(item => item.id.toString() === productId.toString());

export const getQueryParams = (queryString: string) => {
  const stringToProcess = queryString.substr(1, queryString.length);
  if (!stringToProcess) return {};
  const paramArray = stringToProcess.split('&');
  const paramsData = paramArray?.reduce((prev, item: string) => {
    const [key, value] = item.split('=');
    prev[key] = value;
    return prev;
  }, {} as { [key: string]: string });
  return paramsData;
};

export const gastbyLinkResolver = (doc: { uid: string }) => {
  return '/blog/' + doc.uid;
};

export const setInviteCode = (code: string) => {
  isBrowser() && window.localStorage.setItem('invite_code', code);
};

export const applyInviteCodeVoucher = async () => {
  const inviteCode = isBrowser()
    ? window.localStorage.getItem('invite_code')
    : '';
  if (inviteCode) {
    await addInviteCodeToUserSession(inviteCode);
    window.localStorage.removeItem('invite_code');
  }
};

export const getProductEntity = (
  products: Product[],
  selectedEntity: keyof Product,
  selectedEntityValue: any,
  requiredEntity: keyof Product,
) => {
  const selectedProduct = products.find(
    product => product[selectedEntity] === selectedEntityValue,
  );
  return selectedProduct && selectedProduct[requiredEntity];
};

export const getImageSource = (
  product: Product | ProductInfo = {} as Product,
) => {
  return get(product, 'images[0].original') || get(product, 'image');
};

export const isProductOfAttributeValue = (
  product: Product | ProductInfo,
  attributeValue: ProductAttributeValues,
) => {
  return product.product_attributes.find(
    attribute =>
      attribute.name === ProductAttributeName.AVAILABLE_AS &&
      attribute.value.indexOf(attributeValue) !== -1,
  );
};

export const productList = [
  { label: '5kg Chicken Dog Box', value: '7', },
  { label: '10kg Chicken Dog Box', value: '105' },
  { label: '15kg Chicken Dog Box', value: '162' },
  { label: '20kg Chicken Dog Box', value: '156' },
  { label: 'Dog Food - Luxe Lamb 2.5kg', value: '203' },
  { label: 'Dog Food - Luxe Lamb 5kg', value: '204' },
  { label: 'Dog Food - Luxe Lamb 7.5kg', value: '205' },
  { label: 'Dog Food - Luxe Lamb 10kg', value: '206' },
  { label: 'Dog Food - Luxe Lamb 12.5kg', value: '217' },
]

export const convertToTitleCase = (str: string): string => {
  const specialChars: string[] = ['{', '}', '[', ']', '(', ')'];
  const exclusionList: string[] = ['a', 'an', 'and', 'as', 'at', 'but', 'by', 'for', 'from', 'in', 'into', 'of', 'off', 'on', 'onto', 'or', 'out', 'over', 'the', 'to', 'up', 'with'];
  const words: string[] = str.split(' ');

  for (let i = 0; i < words.length; i++) {
    let word: string = words[i];

    if (!exclusionList.includes(word.toLowerCase())) {
      if (word[0].match(/[a-zA-Z]/)) {
        words[i] = word.charAt(0).toUpperCase() + word.slice(1);
      } else {
        let startIdx: number = 0;

        while (startIdx < word.length && !word[startIdx].match(/[a-zA-Z]/)) {
          startIdx++;
        }

        if (startIdx > 0) {
          words[i] =
            word.slice(0, startIdx) +
            word.charAt(startIdx).toUpperCase() +
            word.slice(startIdx + 1);
        }
      }
    }
  }
  return words.join(' ');
}

export const roundPrice = (value: number): any => {
  return (Math.round(value * 100) / 100).toFixed(2);
}
export const convertSpecifictags = (htmlString: string): string => {
  // Regular expression to match <h1> and <h2> tags
  const headingRegex: RegExp = /<h[1-2][^>]*>(.*?)<\/h[1-2]>/g;
  // Replace the text inside <h1> and <h2> tags with uppercase
  const convertedString: string = htmlString.replace(headingRegex, (match: string, content: string) => {
    return match.replace(content, convertToTitleCase(content));
  });
  return convertedString;
}

export const deBounce = <T extends (...args: any[]) => any>(func: T, delay: number) => {
  let timerId: NodeJS.Timeout;
  return (...args: Parameters<T>): Promise<ReturnType<T>> => {
    clearTimeout(timerId);
    return new Promise((resolve) => {
      timerId = setTimeout(async () => {
        const result = await func(...args);
        resolve(result);
      }, delay);
    });
  };
};

export const calculateNextOrder = (date: string, frequency: Number) => {
  //if skipped
  let momentDate = moment(date, 'yyyy-MM-DD')
    .add('days', frequency + '')
    .format('yyyy-MM-DD');
  return momentDate;
};

export const checkWordExistence = (sentences: string[], word: string): boolean => {
  try {
    return sentences.some(sentence => sentence.toLowerCase().includes(word.toLowerCase()));
  } catch {
    return false;
  }
};